import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { ExternalLink, NamedLink } from '../../components';
import { OrangeBtn } from '../Button/ButtonSkin';

import { Link } from 'react-router-dom';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { setSelectedPlan } from '../../ducks/subscriptionReducer.duck';

import { useHistory } from 'react-router-dom';
const OneTimePaymentSection = styled.div`
    border-radius: 8px;
    box-shadow: 3px 3px 20px 0px rgba(0, 0, 0, 0.07);
    padding: 38px 35px 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 25px;
    max-width: 630px;
    margin-top: 10px;
    align-items: center;

    h3 {
        font-size: 19px;
        line-height: 27px;
        font-weight: 500;
    }
    p {
        margin-top: 0px;
        font-weight: 400;
        line-height: 180%;
    }
    button {
        margin: auto;
    }

    .info-txt {
        line-height: 150%;
        margin-top: 10px;
        a {
            font-weight: 600;
            color: ${({ theme }) => theme.colors.title_green};
            &:hover {
                text-decoration: underline;
            }
        }
        ul {
            margin-top: 10px;
            margin-left: 30px;
            text-align: left;
            width: 100%;
            li {
                list-style-type: disc;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.media.tab}) {
        width: 100%;
        padding: 16px;
        flex-wrap: wrap;
        gap: 12px;
        margin-top: 10px;

        h3 {
            font-size: 17px;
            line-height: 25px;
        }
    }
`;

const OneTimePaymentBox = () => {
    const history = useHistory();
    const googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS;
    const dispatch = useDispatch();

    const selectedPlan = useSelector(state => state.subscription.selectedPlan);

    const handleOneTimePayment = () => {
        dispatch(
            setSelectedPlan({
                planTitle: 'One-time payment',
                plan: 'oneoff',
                planType: 'unico',
                amount: 0,
            })
        );

        if (selectedPlan) {
            googleAnalytics &&
                window.gtag('event', 'link_onetime_payment', {
                    send_to: 'G-34FQJZ2P9G',
                    plan_type: selectedPlan.planType,
                    plan: selectedPlan.plan,
                    price: selectedPlan.amount,
                });
        }
        history.push('/subscription/signup'); // Navigate to payment page after successful cart operation
    };

    return (
        <OneTimePaymentSection className="mx-auto">
            <h3>
                <FormattedMessage id="OneOff.action.title" />
            </h3>
            <p>
                <OrangeBtn onClick={handleOneTimePayment}>
                    <FormattedMessage id="OneOff.button.title" />
                </OrangeBtn>
            </p>

            <div className="info-txt small">
                <h4>
                    <FormattedMessage id="OneOff.info.title" />
                </h4>
                <ul>
                    <li>Flexibility: Pay for your specific needs.</li>
                    <li>Simplicity: No quarterly charges.</li>
                </ul>
            </div>
        </OneTimePaymentSection>
    );
};

export default OneTimePaymentBox;
