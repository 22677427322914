import React from 'react';

import {
    Paisajes,
    AuthenticationPage,
    ContactDetailsPage,
    EmailVerificationPage,
    NotFoundPage,
    PasswordChangePage,
    PasswordRecoveryPage,
    PasswordResetPage,
    ProfileSettingsPage,
    YourListingsPage,
    UpdateCardPage,
    PaymentHistoryPage,
    LandscapePage,
    NewActionPage,
    ActionPage,
    NewProfilePage,
} from './containers';
//import { Personas } from './newContainers';
// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';
//import BusinessPage from './newContainers/Partners/Partners';
import GuardiansPage from './newPages/Guardians/GuardiansPage.js';
//import GuardiansPageOld from './newContainers/Guardians';
import NewLandingPage from './newPages/NewLandingPage/NewLandingPage';

import {
    MyNatureFootprintPage,
    MyNatureHomePage,
    MyNatureImpactPage,
    MyNatureLandscapePage,
    MyNatureProfileAndAccountPage,
} from './newPages/myNaturePage/sections/pages';

import {
    OurNatureFootprintPage,
    OurNatureHomePage,
    OurNatureImpactPage,
    OurNatureLandscapePage,
    OurNatureProfileAndAccountPage,
} from './newPages/ourNaturePage/sections/pages';
import OrganisationDetailsPage from './newPages/organisationCalculator';
import DirectEmissionPage from './newPages/organisationCalculator/DirectEmissionPage/DirectEmission.js';
import ElectricityEmissionPage from './newPages/organisationCalculator/ElectricityPage/electricityEmission.js';
import IndirectEmissionPage from './newPages/organisationCalculator/IndirectEmissionPage/IndirectEmission.js';
import ResultPage from './newPages/organisationCalculator/ResultPage/resultPage.js';
import ProfileCheck from './newComponents/ProfileCheck/ProfileCheck';
import UserRoleCheck from './newComponents/UserCheck/userRoleCheck';
import ActivateAccountPage from './newPages/myNaturePage/sections/pages/ActivateAccount/ActivateAccount';
import ActivatePartnerRolePage from './newPages/ourNaturePage/sections/pages/ActivateRoles/ActivateRoles';
import { BlogsPage, BlogSearchPage } from './newContainers/Blogs';
import { BlogList, BlogDetailPage } from './newPages/Blogs';
import {
    GuardianProfileAndAccountPage,
    GuardianLandAndLandscapePage,
    GuardianDocumentsPage,
    GuardianIncomePage,
    GuardianHomePage,
} from './newPages/guardianNaturePage';
import AboutUsPage from './newPages/AboutUs/AboutUsPage.js';
import PartnersPage from './newPages/Partners/PartnersPage.js';
import FriendsPage from './newPages/Friends/FriendsPage.js';
import Signup from './newPages/MultiStepSubscription/Signup';
import ConfirmPlan from './newPages/MultiStepSubscription/ConfirmPlan.js';
import OneTimePayment from './newPages/MultiStepSubscription/OneTimePayment.js';
import SelectLandscape from './newPages/MultiStepSubscription/SelectLandscape.js';
import MakePayment from './newPages/MultiStepSubscription/MakePayment.js';
//import FinalStep from './newPages/MultiStepSubscription/PaymentSuccess.js';
import SignupPage from './newPages/Signup/SignupPage.js';
import LoginPage from './newPages/Login/LoginPage.js';

import GuardianRole from './newPages/Guardians/MultiStepSignup/GuardianRole.js';
import GuardianSignup from './newPages/Guardians/MultiStepSignup/GuardianSignup/GuardianSignup.js';
import SelectCountry from './newPages/Guardians/MultiStepSignup/SelectCountry.js';
import DocumentWorkFlow from './newPages/Guardians/MultiStepSignup/DocuSign/DocumentWorkFlow.js';
import LandscapeList from './newPages/Landscapes/LandscapeList/index.js';
import LandscapeDetail from './newPages/Landscapes/LandscapeDetail';
import TeamUpPage from './newPages/TeamUp/index.js';
import SiteMapPage from './newPages/SiteMap/index.js';
import PersonalFootprintCalculatorPage from './newPages/PersonalFootprintCalculatorPage/PersonalFootprintCalculatorPage.js';
import FaqsPage from './newPages/Faqs/FaqsPage.js';
import PrivacyPolicyPage from './newPages/PrivacyPolicy/PrivacyPolicyPage.js';
import TermsOfServicePage from './newPages/TermsOfService/TermsOfServicePage.js';
import CookiePolicyPage from './newPages/CookiePolicy/CookiePolicyPage.js';
import { Redirect } from 'react-router-dom';

export const ACCOUNT_SETTINGS_PAGES = [
    'ContactDetailsPage',
    'PasswordChangePage',
    'PayoutPreferencesPage',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;
const handlePy = res => console.log(res);
// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.

const routeConfiguration = () => {
    return [
        {
            path: '/',
            name: 'LandingPage',
            component: props => <NewLandingPage {...props} />,
        },
        {
            path: '/about',
            name: 'AboutUsPage',
            component: props => <AboutUsPage {...props} />,
        },
        {
            path: '/friends-profile/home',
            name: 'friends-profile/home',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="friend">
                    <MyNatureHomePage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/friends-profile/footprint',
            name: 'friends-profile/footprint',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="friend">
                    <MyNatureFootprintPage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/friends-profile/impact',
            name: 'friends-profile/impact',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="friend">
                    <MyNatureImpactPage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/friends-profile/landscape',
            name: 'friends-profile/landscape',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="friend">
                    <MyNatureLandscapePage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/friends-profile/profileandaccount',
            name: 'friends-profile/profileandaccount',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="friend">
                    <MyNatureProfileAndAccountPage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/friends-profile/activateaccount',
            name: 'friends-profile/activateaccount',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="friend">
                    <ActivateAccountPage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/partner-profile/home',
            name: 'partner-profile/home',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="partner">
                    <OurNatureHomePage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/partner-profile/footprint',
            name: 'partner-profile/footprint',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="partner">
                    <OurNatureFootprintPage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/partner-profile/impact',
            name: 'partner-profile/impact',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="partner">
                    <OurNatureImpactPage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/partner-profile/landscape',
            name: 'partner-profile/landscape',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="partner">
                    <OurNatureLandscapePage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/partner-profile/profileandaccount',
            name: 'partner-profile/profileandaccount',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="partner">
                    <OurNatureProfileAndAccountPage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/partner-profile/activateroles',
            name: 'partner-profile/activateroles',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="partner">
                    <ActivatePartnerRolePage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/guardian-profile/profileandaccount',
            name: 'guardian-profile/profileandaccount',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="guardian">
                    <GuardianProfileAndAccountPage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/guardian-profile/income',
            name: 'guardian-profile/income',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="guardian">
                    <GuardianIncomePage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/guardian-profile/home',
            name: 'guardian-profile/home',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="guardian">
                    <GuardianHomePage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/guardian-profile/documents',
            name: 'guardian-profile/documents',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="guardian">
                    <GuardianDocumentsPage {...props} />
                </UserRoleCheck>
            ),
        },
        {
            path: '/guardian-profile/landandlandscape',
            name: 'guardian-profile/landandlandscape',
            auth: true,
            authPage: 'LoginPage',
            component: props => (
                <UserRoleCheck accpetedRole="guardian">
                    <GuardianLandAndLandscapePage {...props} />
                </UserRoleCheck>
            ),
        },
        // {
        //     path: '/land-managers-old',
        //     name: 'GuardiansPage-old',
        //     component: props => <GuardiansPageOld {...props} />,
        // },
        {
            path: '/land-managers',
            name: 'GuardiansPage',
            component: props => <GuardiansPage {...props} />,
        },

        {
            path: '/landscapes-old',
            name: 'Paisajes',
            component: props => <Paisajes {...props} />,
        },
        {
            path: '/landscapes',
            name: 'Paisajes',
            component: props => <LandscapeList {...props} />,
        },
        {
            path: '/people',
            name: 'Personas',
            component: props => <FriendsPage {...props} />,
        },
        {
            path: '/organizations',
            name: 'BusinessPage',
            component: props => <PartnersPage {...props} />,
        },

        // {
        //     path: '/friends',
        //     name: 'Friends',
        //     component: props => <Personas {...props} />,
        // },

        {
            path: '/landscapes-old/:slug',
            name: 'ListingPage',
            component: props => <LandscapePage {...props} />,
        },
        {
            path: '/landscapes/:slug',
            name: 'ListingPage',
            component: props => <LandscapeDetail {...props} />,
        },
        {
            path: '/action/:slug',
            name: 'ActionPage',
            component: props => <ActionPage {...props} />,
        },

        {
            path: '/new-action',
            name: 'NewActionPage',
            auth: true,
            component: props => <NewActionPage {...props} />,
        },
        {
            path: '/user/:slug',
            name: 'ProfilePage',
            component: props => <NewProfilePage {...props} />,
            // loadData: ProfilePage.loadData,
        },
        {
            path: '/profile-settings/myNature',
            name: 'MyNatureProfileSettingsPage',
            auth: true,
            authPage: 'LandingPage',
            component: props => <MyNatureProfileAndAccountPage {...props} />,
        },
        {
            path: '/profile-settings/ourNature',
            name: 'OurNatureProfileSettingsPage',
            auth: true,
            authPage: 'LandingPage',
            component: props => <OurNatureProfileAndAccountPage {...props} />,
        },
        // {
        //     path: '/login',
        //     name: 'LoginPage',
        //     component: props => <AuthenticationPage {...props} tab="login" />,
        // },
        // {
        //     path: '/signup',
        //     name: 'SignupPage',
        //     component: props => <AuthenticationPage {...props} tab="signup" />,
        // },
        {
            path: '/recover-password',
            name: 'PasswordRecoveryPage',
            component: props => <PasswordRecoveryPage {...props} />,
        },
        {
            path: '/account',
            name: 'AccountSettingsPage',
            auth: true,
            authPage: 'LoginPage',
            component: () => <NamedRedirect name="ContactDetailsPage" />,
        },
        {
            path: '/account/contact-details',
            name: 'ContactDetailsPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <ContactDetailsPage {...props} />,
            loadData: ContactDetailsPage.loadData,
        },
        {
            path: '/account/tus-listings',
            name: 'YourListingsPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <YourListingsPage {...props} />,
            loadData: YourListingsPage.loadData,
        },
        {
            path: '/account/change-password',
            name: 'PasswordChangePage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <PasswordChangePage {...props} />,
        },
        {
            path: '/account/update-card',
            name: 'UpdateCardPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <UpdateCardPage {...props} />,
        },
        {
            path: '/terms-of-service',
            name: 'TermsOfServicePage',
            component: props => <TermsOfServicePage {...props} />,
        },
        {
            path: '/privacy-policy',
            name: 'PrivacyPolicyPage',
            component: props => <PrivacyPolicyPage {...props} />,
        },
        {
            path: '/cookie-policy',
            name: 'CookiePolicyPage',
            component: props => <CookiePolicyPage {...props} />,
        },
        {
            path: '/notfound',
            name: 'NotFoundPage',
            component: props => <NotFoundPage {...props} />,
        },

        // Do not change this path!
        //
        // The API expects that the application implements /reset-password endpoint
        {
            path: '/reset-password',
            name: 'PasswordResetPage',
            component: props => <PasswordResetPage {...props} />,
        },

        // Do not change this path!
        //
        // The API expects that the application implements /verify-email endpoint
        {
            path: '/verify-email',
            name: 'EmailVerificationPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <EmailVerificationPage {...props} />,
            loadData: EmailVerificationPage.loadData,
        },
        {
            path: '/faq',
            name: 'FaqsPage',
            component: props => <FaqsPage {...props} />,
        },
        {
            path: '/account/payment-history',
            name: 'PaymentHistoryPage',
            auth: true,
            authPage: 'LoginPage',
            component: props => <PaymentHistoryPage {...props} />,
        },
        {
            path: '/people/personal-footprint-calculator',
            name: 'PersonalFootprintCalculatorPage',
            component: props => <PersonalFootprintCalculatorPage {...props} />,
        },
        {
            path: '/organisation/calculator/direct-emission',
            name: '/organisation/calculator/direct-emission',
            auth: true,
            organisationStateCheck: true,
            organisationRedirectPath: '/organisation/calculator',
            authPage: 'LoginPage',
            component: props => <DirectEmissionPage {...props} />,
        },
        {
            path: '/organisation/calculator/electricity-emission',
            name: '/organisation/calculator/electricity-emission',
            auth: true,
            organisationStateCheck: true,
            organisationRedirectPath: '/organisation/calculator',
            authPage: 'LoginPage',
            component: props => <ElectricityEmissionPage {...props} />,
        },
        {
            path: '/organisation/calculator/indirect-emission',
            name: '/organisation/calculator/indirect-emission',
            auth: true,
            organisationStateCheck: true,
            organisationRedirectPath: '/organisation/calculator',
            authPage: 'LoginPage',
            component: props => <IndirectEmissionPage {...props} />,
        },
        {
            path: '/organisation/calculator/result',
            name: '/organisation/calculator/result',
            auth: true,
            organisationStateCheck: true,
            organisationRedirectPath: '/organisation/calculator',
            authPage: 'LoginPage',
            component: props => <ResultPage {...props} />,
        },
        {
            path: '/organisation/calculator',
            name: '/organisation/calculator',
            auth: true,
            authPage: 'LoginPage',
            component: props => <OrganisationDetailsPage {...props} />,
        },

        {
            path: '/profile-check',
            name: 'ProfileCheck',
            auth: true,
            authPage: 'LoginPage',
            component: props => <ProfileCheck {...props} />,
        },
        {
            path: '/blog/:newsSlug',
            name: 'BlogDetailPage',
            component: props => <BlogDetailPage {...props} />,
        },
        {
            path: '/blog',
            name: 'BlogList',
            component: props => <BlogList {...props} />,
        },
        {
            path: '/blog/search/:newsKeywords',
            name: 'BlogList',
            component: props => <BlogList {...props} />,
        },
        {
            path: '/blog/category/:newsCategory',
            name: 'BlogList',
            component: props => <BlogList {...props} />,
        },
        {
            path: '/blog/landscape/:newsLandscape',
            name: 'BlogList',
            component: props => <BlogList {...props} />,
        },
        {
            path: '/blog/tag/:newsTag',
            name: 'BlogList',
            component: props => <BlogList {...props} />,
        },
        {
            path: '/blog/author/:newsWriter',
            name: 'BlogList',
            component: props => <BlogList {...props} />,
        },
        // this will be removed later when /news is implemented for the news section on wordpress //
        {
            path: '/news/:newsSlug',
            component: match => <Redirect to={`/blog/${match.params.newsSlug}`} />,
        },
        {
            path: '/subscription/signup',
            name: 'Subscription',
            component: props => <Signup {...props} />,
        },
        {
            path: '/subscription/confirm-plan',
            name: 'Plan',
            auth: true,
            component: props => <ConfirmPlan {...props} />,
        },
        {
            path: '/subscription/onetime-payment',
            name: 'Plan',
            auth: true,
            component: props => <OneTimePayment {...props} />,
        },
        {
            path: '/subscription/select-landscape',
            name: 'Plan',
            auth: true,
            component: props => <SelectLandscape {...props} />,
        },
        {
            path: '/subscription/payment',
            name: 'Plan',
            auth: true,
            authPage: 'LoginPage',
            component: props => <MakePayment {...props} />,
        },

        {
            path: '/land-managers/role',
            name: 'GuardianRole',
            component: props => <GuardianRole {...props} />,
        },
        {
            path: '/land-managers/signup',
            name: 'GuardianSignup',
            component: props => <GuardianSignup {...props} />,
        },
        {
            path: '/land-managers/select-country',
            name: 'SelectCountry',
            auth: true,
            authPage: 'GuardianSignup',
            component: props => <SelectCountry {...props} />,
        },
        {
            path: '/land-managers/sign-document',
            name: 'DocumentWorkFlow',
            auth: true,
            authPage: 'GuardianSignup',
            component: props => <DocumentWorkFlow {...props} />,
        },
        {
            path: '/signup',
            name: 'SignupPage',
            component: props => <SignupPage {...props} />,
        },
        {
            path: '/login',
            name: 'LoginPage',
            component: props => <LoginPage {...props} />,
        },
        {
            path: '/team-up',
            name: 'TeamUpPage',
            component: props => <TeamUpPage {...props} />,
        },
        {
            path: '/sitemap',
            name: 'SiteMap',
            component: props => <SiteMapPage {...props} />,
        },
    ];
};

export default routeConfiguration;
