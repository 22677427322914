import React from 'react';
import { ExternalLink } from '../../../../../components';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { useModal } from '../../../../../services/context/ModalProvider';
import ModalFormBox from '../../../../../newComponents/ContactModalForm';
import styled from 'styled-components';

const ContactSubmenu = () => {
    const { openModal, closeModal } = useModal();

    const handleOpenDefaultForm = e => {
        // supply the form as a React element
        e.preventDefault();
        openModal(<ModalFormBox closeBtn={() => closeModal()} subjectLine="Contact us" />);
    };
    return (
        <div className="submenu" onClick={e => e.stopPropagation()}>
            <ul>
                <li>
                    <ExternalLink href="https://calendly.com/frankhajek/30min">
                        <FormattedMessage id="NewLandingPage.header.scheduleCall" />
                    </ExternalLink>
                </li>
                <li>
                    <Link onClick={handleOpenDefaultForm}>Send an e-email</Link>
                </li>
                <li>
                    <Link to="/team-up">Team up</Link>
                </li>
            </ul>
        </div>
    );
};

export default ContactSubmenu;
