import React, { useEffect, useRef } from 'react';

/**
 * Basic controlled modal built around the native HTML <dialog>.
 * - `isOpen` (boolean): controls whether the dialog is open or closed.
 * - `onClose` (function): called when user clicks on the backdrop or otherwise needs to close.
 */
export default function BasicModal({ isOpen, onClose, children }) {
    const dialogRef = useRef(null);

    useEffect(() => {
        // Show/hide the modal based on `isOpen`
        if (dialogRef.current) {
            if (isOpen) {
                // Opens the native <dialog>
                dialogRef.current.showModal();
            } else {
                // Closes the native <dialog>
                dialogRef.current.close();
            }
        }
    }, [isOpen]);

    // Close the dialog if user clicks on the backdrop
    const handleBackdropClick = e => {
        if (e.target === dialogRef.current) {
            onClose();
        }
    };

    return (
        <dialog ref={dialogRef} onClick={handleBackdropClick}>
            {children}
        </dialog>
    );
}
