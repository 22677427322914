import { sendContactEmailData } from '../coreApi';

export const sendContactEmail = async emailData => {
    try {
        await sendContactEmailData(emailData);
        return {
            status: 'success',
        };
    } catch (err) {
        return {
            status: 'error',
            message: 'Something went wrong. Please try again.',
        };
    } finally {
    }
};
