import React, { createContext, useContext, useState } from 'react';
import BasicModal from '../../newComponents/Modal/BasicModal';

const ModalContext = createContext();

export function ModalProvider({ children }) {
    const [isOpen, setIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const openModal = contentNode => {
        setModalContent(contentNode); // store the passed-in React element
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
        setModalContent(null);
    };

    return (
        <ModalContext.Provider value={{ isOpen, modalContent, openModal, closeModal }}>
            {children}

            <BasicModal isOpen={isOpen} onClose={closeModal}>
                {/* Render whatever node we have in modalContent */}
                {modalContent}
            </BasicModal>
        </ModalContext.Provider>
    );
}

export function useModal() {
    return useContext(ModalContext);
}
