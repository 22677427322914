import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Header from '../NewLandingPage/Sections/Header/Header';
import Footer from '../NewLandingPage/Sections/Footer/Footer';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Page } from '../../components';

import HeroSection from '../../newComponents/HeroSection/HeroSection';
import { OrangeBtn } from '../../newComponents/Button/ButtonSkin';
import { Link } from 'react-router-dom';
import { TeamUpSection, MultiColAction } from './Styles';
import { ExternalLink } from '../../components';

import TeamUpHeroDesktop from '../../assets/newAssets/bg-team-up.webp';
import bgGreenTexture from '../../assets/newAssets/bg-texture-green.jpg';
import { useModal } from '../../services/context/ModalProvider';
import ModalFormBox from '../../newComponents/ContactModalForm';
import { FaPhoneAlt } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa';

const CallSection = ({ subject }) => {
    const { openModal, closeModal } = useModal();

    const handleOpenDefaultForm = (e, subjectValue) => {
        // supply the form as a React element
        e.preventDefault();
        openModal(<ModalFormBox closeBtn={() => closeModal()} subjectLine={subjectValue} />);
    };
    return (
        <div className="call-section">
            <ul className="flex flex-col items-center">
                <li className="flex items-center">
                    <ExternalLink
                        href="https://calendly.com/frankhajek/30min"
                        className="icon flex items-center"
                    >
                        <FaPhoneAlt /> <FormattedMessage id="PartnersPage.ContactAction.phone" />
                    </ExternalLink>
                </li>
                <li className="flex items-center">
                    <Link
                        className="icon flex items-center"
                        onClick={e => handleOpenDefaultForm(e, subject)}
                    >
                        <FaEnvelope /> <FormattedMessage id="FriendsPage.ContactAction.email" />
                    </Link>
                </li>
            </ul>
        </div>
    );
};

const TeamUpPage = ({ location, history, user }) => {
    const googleAnalytics = process.env.REACT_APP_GOOGLE_ANALYTICS;

    const [subjectValue, setSubject] = useState('Contact us');

    const openDialog = subjectValue => {
        setSubject(subjectValue);
        //setOpen(true);
    };

    const pragraph = ["Great, you'd like to take action!"];

    return (
        <Page>
            <Header currentPath={location.pathname} user={user} history={history} />
            <TeamUpSection bgGreenTexture={bgGreenTexture}>
                <HeroSection
                    title="How would you like to"
                    titleline="team up?"
                    titleclass="text-white"
                    paratext={pragraph}
                    paraclass="text-white small-title absolute"
                    heroBg="green-bg"
                    heroImgD={TeamUpHeroDesktop}
                    alttxt="TeamUp"
                />
                <MultiColAction bgGreenTexture={bgGreenTexture}>
                    <div className="green-bg">
                        <div className="wrapper w-full mx-auto flex">
                            <div className="col-box text-center">
                                <h3>Footprint measurements</h3>
                                <p>
                                    Looking for a footprint measurement tailored to your
                                    organisation? Engage with one of our expert footprint advisors.
                                </p>

                                <CallSection subject="Footprint measurements" />
                            </div>
                            <div className="col-box text-center">
                                <h3>Solve operational or sustainability challenges</h3>
                                <p>
                                    Need to solve an operational or sustainability challenge? Engage
                                    with one of our expert engineers.
                                </p>

                                <CallSection subject="Solve operational or sustainability challenges" />
                            </div>
                            <div className="col-box text-center">
                                <h3>Positive Nature & climate actions</h3>
                                <p>
                                    Aiming to support positive Nature & climate actions? Connect and
                                    regenerate your footprint in a Regenera landscape.
                                </p>
                                <CallSection subject="Positive Nature & climate actions" />
                            </div>
                        </div>
                    </div>
                </MultiColAction>
            </TeamUpSection>
            <Footer history={history} />
        </Page>
    );
};

const mapStateToProps = storeState => ({
    user: storeState.user.currentUser
        ? {
              initials: storeState.user.currentUser.attributes.profile.abbreviatedName,
              name:
                  storeState.user.currentUser.attributes.profile.firstName +
                  ' ' +
                  storeState.user.currentUser.attributes.profile.lastName,
          }
        : null,
});

export default connect(mapStateToProps)(withRouter(TeamUpPage));
