import React from 'react';
import { ErrorMessage, useField } from 'formik';

const TextAreaField = ({ label, ...props }) => {
    const [field, meta, helpers] = useField(props);

    return (
        <>
            {label ? (
                <label htmlFor={field.name} className="small">
                    {label}
                </label>
            ) : null}

            <textarea
                {...field}
                {...props}
                onBlur={e => {
                    field.onBlur(e);
                    helpers.setTouched(true); // Mark the field as touched after the blur event
                }}
                onChange={e => {
                    field.onChange(e);
                    // If there's an error and it's already touched, hide the error once the user starts typing
                    if (meta.error && meta.touched) {
                        helpers.setTouched(false);
                    }
                }}
            />

            {meta.touched && meta.error ? (
                <ErrorMessage component="span" name={field.name} className="error-text small" />
            ) : null}
        </>
    );
};

export default TextAreaField;
